<template>
  <div>
    <div class="mb-2">
      <b-modal id="partial-transfer-detail-modal" title="Transfer Details" size="lg" hide-footer>
        <div class="modal-content bg-white rounded">
         <table class="table table-sm table-bordered text-center">
           <thead>
           <tr>
             <th>SL</th>
             <th>Transfer Qty</th>
             <th>Dispute Qty</th>
             <th>Transfer Status</th>
             <th>Transfer Comment</th>
             <th>Hub Comment</th>
             <th>Transfer Date</th>
           </tr>
           </thead>
           <tbody>
           <tr v-for="(requisition_transfer, index) in requisition_transfers" :key="index">
             <td>{{ index + 1 }}</td>
             <td>{{ requisition_transfer.transfer_amount }}</td>
             <td>{{ requisition_transfer.dispute_amount }}</td>
             <td>
               <a-tag color="#f50" v-if="requisition_transfer.transfer_status == 'Pending'">Pending</a-tag>
               <a-tag color="#f50" v-if="requisition_transfer.transfer_status == 'Rejected'">Rejected</a-tag>
               <a-tag color="#87d068"  v-if="requisition_transfer.transfer_status == 'Accepted'">Accepted</a-tag>
               <a-tag color="#E0A800"  v-if="requisition_transfer.transfer_status == 'Partial'">Partial</a-tag>
             </td>
             <td>{{ requisition_transfer.transferer_comment }}</td>
             <td>{{ requisition_transfer.hub_comment }}</td>
             <td>{{ customDate(requisition_transfer.created_at) }}</td>
           </tr>
           </tbody>
         </table>
          <h5 class="text-center text-secondary" v-if="requisition_transfers.length === 0">
            <Empty :image="simpleImage"></Empty>
          </h5>
          <div class="form-row mt-3 mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="danger" class="text-center" @click="$bvModal.hide('partial-transfer-detail-modal')">Cancel</a-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>

import moment from 'moment'
import { Empty } from 'ant-design-vue'

export default {
  name: 'partial_transfer_detail',
  props: ['requisition_transfers'],
  components: { Empty },
  data() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() { },
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
  },
}
</script>

<style>

</style>
