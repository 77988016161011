<template>
  <div class="row" v-if="Object.keys(requisition).length !== 0">
    <requisition-hub-detail :requisition="requisition"></requisition-hub-detail>
    <requisition-header-detail :requisition="requisition" :spinning="spinning" :delayTime="delayTime"></requisition-header-detail>
    <requisition-header-status :current="current" :requisition="requisition"></requisition-header-status>
    <div class="col-md-12" v-if="requisition.hub_transfer_chalan_form_picture">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="float-left text-center">
              <a :href="requisition.hub_transfer_chalan_form_picture_url" target="_blank" download>
                <img :src="requisition.hub_transfer_chalan_form_picture_url" alt="" class="rounded img-thumbnail" width="80" height="80"/>
                <div class="chalan-form-pictre-footer">Figure: Hub Received Products Chalan Form</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form action="">
            <table class="table table-hover table-bordered table-sm text-center">
              <thead>
              <tr>
                <th>Product Name</th>
                <th>Product Unit</th>
                <th>Status</th>
                <th>Request Qty</th>
                <th>Accepted Qty</th>
                <th>Transfer Qty</th>
                <th>Transfer Accept Status</th>
                <th style="color: red">Dispute Qty</th>
                <th>Option</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(requisition_item, index) in requisition_items" :key="index">
                <td>{{ requisition_item.product.name }}</td>
                <td>{{ requisition_item.product.unit }}</td>
                <td>
                  <a-tag color="#f50" v-if="requisition_item.status == 'Pending'">Pending</a-tag>
                  <a-tag color="#f50" v-if="requisition_item.status == 'Rejected'">Rejected</a-tag>
                  <a-tag color="#87d068"  v-if="requisition_item.status == 'Accepted'">Accepted</a-tag>
                  <a-tag color="#E0A800"  v-if="requisition_item.status == 'Partial'">Partial</a-tag>
                </td>
                <td>{{ requisition_item.amount }}</td>
                <td>{{ requisition_item.accepted_amount }}</td>
                <td>{{ requisition_item.transfer_amount }}</td>
                <td>
                  <a-tag color="#f50" v-if="requisition_item.transfer_status == 'Pending'">Pending</a-tag>
                  <a-tag color="#f50" v-if="requisition_item.transfer_status == 'Rejected'">Rejected</a-tag>
                  <a-tag color="#87d068"  v-if="requisition_item.transfer_status == 'Accepted'">Accepted</a-tag>
                  <a-tag color="#E0A800"  v-if="requisition_item.transfer_status == 'Partial'">Partial</a-tag>
                </td>
                <td style="color: red">{{ requisition_item.dispute_amount }}</td>
                <td>
                  <a-tooltip placement="top">
                    <template slot="title"><span>Transfer Details</span></template>
                    <a  class="btn btn-sm btn-info mr-1" href="javascript: void(0);" @click.prevent="transferDetail(requisition_item.requisition_transfers), $bvModal.show('partial-transfer-detail-modal')"><i class="fa fa-info-circle mr-2"></i>Details</a>
                  </a-tooltip>
                </td>
              </tr>
              </tbody>
            </table>
            <a-skeleton active :loading="loading"></a-skeleton>
          </form>
        </div>
      </div>
    </div>
    <partial-transfer-detail
    :requisition_transfers="requisition_transfers">
    </partial-transfer-detail>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import moment from 'moment'
import partialTransferDetail from '@/views/requisition/transfer/modal/partial_transfer_detail'
import requisitionHeaderDetail from '@/views/requisition/partial/requisition_header_detail'
import requisitionHeaderStatus from '@/views/requisition/partial/requisition_header_status'
import requisitionHubDetail from '@/views/requisition/partial/requisition_hub_detail'

export default {
  name: 'transfer_view',
  components: { partialTransferDetail, requisitionHeaderDetail, requisitionHeaderStatus, requisitionHubDetail },
  data() {
    return {
      requisition: {},
      requisition_items: [],
      requisition_item: { },
      requisition_transfers: [],
      loading: false,
      spinning: false,
      delayTime: 500,
      current: 0,
    }
  },
  mounted() {
    this.getRequisition()
  },
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
    getRequisition() {
      const requisitionId = this.$route.params.requisition_id
      this.loading = true
      this.spinning = true
      apiClient.get('api/requisition/hub/transfer/detail/' + requisitionId)
        .then(response => {
          this.loading = false
          this.spinning = false
          const data = response.data
          this.requisition = data.requisition
          this.requisition_items = data.requisition_items
          this.current = data.max_item_requisition_status
        })
        .catch(error => {
          console.log(error)
        })
    },
    transferDetail(requisitionTransfers) {
      this.requisition_transfers = []
      this.requisition_transfers = requisitionTransfers
    },
  },
}
</script>

<style scoped>
table {
  position: relative;
}

th {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
}
.chalan-form-pictre-footer{
  color: #595c97;
  font-size: 16px;
  line-height: 32px;
}
</style>
